import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customers: {
    limit: 10,
    skip: 0,
    customerList: [],
    searchTerm: '',
    statusFilter: { id: 'all', name: 'All' },
    isActiveCustomer: true,
    totalActiveCustomers: 0,
    totalInactiveCustomers: 0,
    activeCustomers: {
      list: [],
      total: 0,
      limit: 10,
      skip: 0,
      searchTotal: 0,
      searchTerm: '',
      searchParam: 'Customer ID',
      isLoading: false,
      loadingMessage: 'Loading...',
      showingSearchResults: false,
      selectedCustomers: []
    },
    inActiveCustomers: {
      list: [],
      total: 0,
      limit: 10,
      skip: 0,
      searchTotal: 0,
      searchTerm: '',
      searchParam: 'Customer ID',
      isLoading: false,
      loadingMessage: 'Loading...',
      showingSearchResults: false,
      selectedCustomers: []
    },
    accounts: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false
    },
    inventories: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false
    },
    tidbDatabases: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false
    },
    tidbInfraUpgrades: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false
    },
    tidbMigration: {
      notificationMessage: {
        title: '',
        description: '',
        color: ''
      },
      migrationConfiguration: {
        loading: false,
        loadingMessage: 'Loading...',
        masterConfiguration: {
          loading: false,
          loadingMessage: 'Loading...',
          data: {}
        },
        tidbDatabases: {
          loading: false,
          loadingMessage: 'Loading...',
          data: []
        },
        dataToDisplay: {
          InfraId: '',
          DatabaseName: '',
          IsStandaloneDatabase: false
        },
        migrationDialog: {
          state: false,
          loading: false,
          loadingMessage: 'Loading...'
        }
      },
      customersInfo: {
        isLoading: false,
        loadingMessage: 'Loading...'
      },
      groupDetails: {
        isLoading: false,
        loadingMessage: 'Loading...',
        groupId: '',
        data: null,
        customers: [],
        migrationCompleted: false
      },
      customersDetails: {
        isLoading: false,
        loadingMessage: 'Loading...',
        data: {}
      },
      customerMigrationDetails: {
        isLoading: false,
        loadingMessage: 'Loading...',
        data: {}
      },
      customerValidationDetails: {
        isLoading: false,
        loadingMessage: 'Loading...',
        data: {}
      }
    },
    releases: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false
    },
    dormantOrgs: {
      list: [],
      total: 0,
      loadingMessage: 'Loading...',
      isLoading: false,
      showingSearchResults: false,
      searchTerm: ''
    },
    tidbMigrationGroups: {
      isLoading: false,
      loadingMessage: 'Loading...',
      list: [],
      showingSearchResults: false,
      searchTerm: '',
      nextToken: null,
      prevTokens: [],
      limit: 10
    },
    openSearchShards: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    indices: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    orphanIndices: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    isLoading: false
  },
  customer: {
    details: {
      data: {},
      isLoading: false,
      loadingMessage: 'Loading...'
    },
    tracking: {
      upgrade: {
        list: [],
        total: 0,
        loadingMessage: 'Loading...',
        isLoading: false
      },
      onboard: {
        list: [],
        total: 0,
        loadingMessage: 'Loading...',
        isLoading: false
      },
      offboard: {
        list: [],
        total: 0,
        loadingMessage: 'Loading...',
        isLoading: false
      },
      tidbMigrationTracking: {
        list: [],
        total: 0,
        loadingMessage: 'Loading...',
        isLoading: false
      }
    },
    credentials: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    license: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    matchingOrgs: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    externalIntegration: {
      data: {},
      loadingMessage: 'Loading...',
      iaLoaded: false
    },
    indices: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    apiConnections: {
      data: {},
      loadingMessage: 'Loading...',
      isLoading: false
    },
    dataSyncStatus: {
      data: [],
      loadingMessage: 'Loading...',
      isLoading: false
    },
    lastLoggedIn: {
      data: {
        userName: '',
        emailId: '',
        lastLoginDate: ''
      },
      loadingMessage: 'Loading...',
      isLoading: false
    }
  },
  currentCustomer: {},
  newCustomerConfiguration: {},
  toggleCustomerConfigurationSaveButton: true,
  changedFields: [],
  currentEnv: {},
  monitoringEnv: {},
  featureFlags: []
};

export const rlsSlice = createSlice({
  name: 'rls',
  initialState,
  reducers: {
    handleUnsetEnvDetails: (state, action) => {
      state[action.payload.name].activeCustomers =
        initialState[action.payload.name].activeCustomers;
      state[action.payload.name].inActiveCustomers =
        initialState[action.payload.name].inActiveCustomers;
      state[action.payload.name].accounts =
        initialState[action.payload.name].accounts;
      state[action.payload.name].inventories =
        initialState[action.payload.name].inventories;
      state[action.payload.name].releases =
        initialState[action.payload.name].releases;
      state[action.payload.name].tidbDatabases =
        initialState[action.payload.name].tidbDatabases;
      state[action.payload.name].tidbInfraUpgrades =
        initialState[action.payload.name].tidbInfraUpgrades;
      state[action.payload.name].dormantOrgs =
        initialState[action.payload.name].dormantOrgs;
      state[action.payload.name].tidbMigration =
        initialState[action.payload.name].tidbMigration;
      state[action.payload.name].tidbMigrationGroups =
        initialState[action.payload.name].tidbMigrationGroups;
    },
    handleUnsetCustomerDetails: (state, action) => {
      state[action.payload.name] = initialState[action.payload.name];
    },
    handleUnsetFeatureFlags: (state) => {
      state['featureFlags'] = initialState['featureFlags'];
    },
    handleSelectedCustomers: (state, action) => {
      // state[action.payload.name][action.payload.type].selectedCustomers =
      //   action.payload.value;
      // if checked is true in payload value then add new selected customers to state
      if (action.payload.value.checked) {
        state[action.payload.name][action.payload.type].selectedCustomers.push(
          action.payload.value
        );
      } else {
        // otherwise find customer to remove from payload and remove the object for deselect customer

        state[action.payload.name][action.payload.type].selectedCustomers =
          state[action.payload.name][
            action.payload.type
          ].selectedCustomers.filter(
            (customer) =>
              customer.CustomerId !== action.payload.value.CustomerId
          );
      }
    },
    handleForceUpdateSelectedCustomers: (state, action) => {
      // do a soft update instead of hard replace
      state[action.payload.name][action.payload.type].selectedCustomers =
        action.payload.value;
    },
    handleDeselectCustomersSelected: (state, action) => {
      state[action.payload.name][action.payload.type].selectedCustomers = [];
    },
    handleSetFeatureFlags: (state, action) => {
      state.featureFlags = action.payload;
    },
    handleUnsetMonitoringEnv: (state) => {
      state.monitoringEnv = initialState.monitoringEnv;
    },
    handleUnsetOpenSearchDetails: (state, action) => {
      state[action.payload.name].indices =
        initialState[action.payload.name].indices;
      state[action.payload.name].orphanIndices =
        initialState[action.payload.name].orphanIndices;
    },
    handleTidbMigrationNotificationMessage: (state, action) => {
      state.customers.tidbMigration.notificationMessage = action.payload;
    },
    handleTidbMigrationCustomerInfo: (state, action) => {
      state.customers.tidbMigration.customersInfo.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.customersInfo.loadingMessage =
        action.payload.loadingMessage;
    },
    handleMasterConfiguration: (state, action) => {
      state.customers.tidbMigration.migrationConfiguration.masterConfiguration.data =
        action.payload.data;
      state.customers.tidbMigration.migrationConfiguration.masterConfiguration.loading =
        action.payload.loading;
      state.customers.tidbMigration.migrationConfiguration.masterConfiguration.loadingMessage =
        action.payload.loadingMessage;
      state.customers.tidbMigration.migrationConfiguration.loading =
        action.payload.loading;
      state.customers.tidbMigration.migrationConfiguration.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbDatabasesForMasterConfiguration: (state, action) => {
      state.customers.tidbMigration.migrationConfiguration.tidbDatabases.data =
        action.payload.data;
      state.customers.tidbMigration.migrationConfiguration.tidbDatabases.loading =
        action.payload.loading;
      state.customers.tidbMigration.migrationConfiguration.tidbDatabases.loadingMessage =
        action.payload.loadingMessage;
      state.customers.tidbMigration.migrationConfiguration.loading =
        action.payload.loading;
      state.customers.tidbMigration.migrationConfiguration.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbMigrationGroupDetails: (state, action) => {
      state.customers.tidbMigration.groupDetails.data = action.payload.data;
      state.customers.tidbMigration.groupDetails.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.groupDetails.loadingMessage =
        action.payload.loadingMessage;
      state.customers.tidbMigration.groupDetails.groupId =
        action.payload.groupId;
    },
    handleTidbMigrationGroupCustomersDetails: (state, action) => {
      state.customers.tidbMigration.groupDetails.customers =
        action.payload.customers;
      state.customers.tidbMigration.groupDetails.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.groupDetails.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbMigrationGroupDetailsMigrationCompleted: (state, action) => {
      state.customers.tidbMigration.groupDetails.migrationCompleted =
        action.payload;
    },
    handleTidbMigrationCustomersDetails: (state, action) => {
      state.customers.tidbMigration.customersDetails.data = action.payload.data;
      state.customers.tidbMigration.customersDetails.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.customersDetails.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbMigrationcustomerMigrationDetails: (state, action) => {
      state.customers.tidbMigration.customerMigrationDetails.data =
        action.payload.data;
      state.customers.tidbMigration.customerMigrationDetails.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.customerMigrationDetails.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbMigrationcustomerValidationDetails: (state, action) => {
      state.customers.tidbMigration.customerValidationDetails.data =
        action.payload.data;
      state.customers.tidbMigration.customerValidationDetails.isLoading =
        action.payload.isLoading;
      state.customers.tidbMigration.customerValidationDetails.loadingMessage =
        action.payload.loadingMessage;
    },
    handleTidbMigrationConfigDataToDisplay: (state, action) => {
      state.customers.tidbMigration.migrationConfiguration.dataToDisplay =
        action.payload;
    },
    handleTidbMigrationDialogState: (state, action) => {
      state.customers.tidbMigration.migrationConfiguration.migrationDialog =
        action.payload;
    },
    handleUnsetTypeDetails: (state, action) => {
      state[action.payload.name][action.payload.type] =
        initialState[action.payload.name][action.payload.type];
    },
    handleAccountsDetails: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleReleases: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleOpenSearchShards: (state, action) => {
      state[action.payload.name][action.payload.type].data =
        action.payload.data;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleInventories: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleTidbDatabases: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleDormantOrgs: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleTidbInfraUpgrades: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.list;
      state[action.payload.name][action.payload.type].total =
        action.payload.total;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleSeachTerm: (state, action) => {
      state[action.payload.name][action.payload.type].searchTerm =
        action.payload.value;
    },
    handleSearchParam: (state, action) => {
      state[action.payload.name][action.payload.type].searchParam =
        action.payload.value;
    },
    handleSearchTotal: (state, action) => {
      state[action.payload.name][action.payload.type].searchTotal =
        action.payload.value;
    },
    handleShowingSearchResults: (state, action) => {
      state[action.payload.name][action.payload.type].showingSearchResults =
        action.payload.value;
    },
    handlePageLimit: (state, action) => {
      state[action.payload.name][action.payload.type].limit =
        action.payload.value;
    },
    handleCustomersList: (state, action) => {
      state[action.payload.name][action.payload.type].list =
        action.payload.value;
    },
    handleCustomerCount: (state, action) => {
      state[action.payload.name][action.payload.type].total =
        action.payload.value;
    },
    handleEnvChange: (state, action) => {
      state.currentEnv = action.payload.value;
    },
    handleMonitoringChange: (state, action) => {
      state.monitoringEnv = action.payload.value;
    },
    handleLoading: (state, action) => {
      state[action.payload.name][action.payload.type].isLoading =
        action.payload.isLoading;
      state[action.payload.name][action.payload.type].loadingMessage =
        action.payload.loadingMessage;
    },
    handleSkip: (state, action) => {
      state[action.payload.name][action.payload.type].skip =
        action.payload.value;
    },
    handleStatusFilter: (state, action) => {
      state[action.payload.name].statusFilter = action.payload.value;
    },
    handleCustomerDetails: (state, action) => {
      state[action.payload.name].details.data = action.payload.data;
      state[action.payload.name].details.isLoading = action.payload.isLoading;
      state[action.payload.name].details.loadingMessage =
        action.payload.loadingMessage;
    },
    handleCustomerCredentials: (state, action) => {
      state[action.payload.name].credentials.data = action.payload.data;
      state[action.payload.name].credentials.isLoading =
        action.payload.isLoading;
      state[action.payload.name].credentials.loadingMessage =
        action.payload.loadingMessage;
    },
    handleIndicesGet: (state, action) => {
      state[action.payload.name].indices.data = action.payload.data;
      state[action.payload.name].indices.isLoading = action.payload.isLoading;
      state[action.payload.name].indices.loadingMessage =
        action.payload.loadingMessage;
    },
    handleOrphanIndicesGet: (state, action) => {
      state[action.payload.name].orphanIndices.data = action.payload.data;
      state[action.payload.name].orphanIndices.isLoading =
        action.payload.isLoading;
      state[action.payload.name].orphanIndices.loadingMessage =
        action.payload.loadingMessage;
    },
    handleApiConnections: (state, action) => {
      state[action.payload.name].apiConnections.data = action.payload.data;
      state[action.payload.name].apiConnections.isLoading =
        action.payload.isLoading;
      state[action.payload.name].apiConnections.loadingMessage =
        action.payload.loadingMessage;
    },
    handleDataSyncStatus: (state, action) => {
      state[action.payload.name].dataSyncStatus.data = action.payload.data;
      state[action.payload.name].dataSyncStatus.isLoading =
        action.payload.isLoading;
      state[action.payload.name].dataSyncStatus.loadingMessage =
        action.payload.loadingMessage;
    },
    handleCustomerLicense: (state, action) => {
      state[action.payload.name].license.data = action.payload.data;
      state[action.payload.name].license.isLoading = action.payload.isLoading;
      state[action.payload.name].license.loadingMessage =
        action.payload.loadingMessage;
    },
    handleMatchingOrgs: (state, action) => {
      state[action.payload.name].matchingOrgs.data = action.payload.data;
      state[action.payload.name].matchingOrgs.isLoading =
        action.payload.isLoading;
      state[action.payload.name].matchingOrgs.loadingMessage =
        action.payload.loadingMessage;
    },
    handleExternalIntegration: (state, action) => {
      state[action.payload.name].externalIntegration.data = action.payload.data;
      state[action.payload.name].externalIntegration.isLoading =
        action.payload.isLoading;
      state[action.payload.name].externalIntegration.loadingMessage =
        action.payload.loadingMessage;
    },
    handleCustomerTrackingIds: (state, action) => {
      state[action.payload.name].tracking[action.payload.type].list =
        action.payload.list;
      state[action.payload.name].tracking[action.payload.type].total =
        action.payload.total;
      state[action.payload.name].tracking[action.payload.type].loadingMessage =
        action.payload.loadingMessage;
      state[action.payload.name].tracking[action.payload.type].isLoading =
        action.payload.isLoading;
    },
    handleLastLoggedIn: (state, action) => {
      state.customer.lastLoggedIn.data = action.payload.data;
      state.customer.lastLoggedIn.isLoading = action.payload.isLoading;
      state.customer.lastLoggedIn.loadingMessage =
        action.payload.loadingMessage;
    },
    setCurrentCustomer: (state, action) => {
      state.currentCustomer = action.payload;
      state.newCustomerConfiguration = action.payload;
      state.toggleCustomerConfigurationSaveButton = true;
      state.changedFields = [];
    },
    setNewCustomerConfiguration: (state, action) => {
      state.newCustomerConfiguration[action.payload.key] = action.payload.value;
      if (state.changedFields.indexOf(action.payload.key) === -1) {
        state.changedFields = [...state.changedFields, action.payload.key];
      } else if (
        state.changedFields.indexOf(action.payload.key) !== -1 &&
        state.currentCustomer[action.payload.key] === action.payload.value
      ) {
        const idx = state.changedFields.indexOf(action.payload.key);
        if (idx > -1) {
          state.changedFields.splice(idx, 1);
        }
      }
    },
    setToggleCustomerConfigurationSaveButton: (state, action) => {
      state.toggleCustomerConfigurationSaveButton = action.payload;
    },
    handleTidbMigrationGroupsDisplay: (state, action) => {
      state.customers.tidbMigrationGroups.list = action.payload;
    },
    handleTidbMigrationGroupsDisplayLoading: (state, action) => {
      state.customers.tidbMigrationGroups.isLoading = action.payload.isLoading;
      state.customers.tidbMigrationGroups.loadingMessage =
        action.payload.loading;
    },
    handleTidbMigrationGroupsDisplayNextToken: (state, action) => {
      state.customers.tidbMigrationGroups.nextToken = action.payload;
    },
    handleTidbMigrationGroupsDisplayLimit: (state, action) => {
      state.customers.tidbMigrationGroups.limit = action.payload;
    },
    handleTidbMigrationGroupsDisplaySearchTerm: (state, action) => {
      state.customers.tidbMigrationGroups.searchTerm = action.payload;
    },
    handleTidbMigrationGroupsDisplayShowingSearchResults: (state, action) => {
      state.customers.tidbMigrationGroups.showingSearchResults = action.payload;
    },
    handleTidbMigrationGroupsDisplayPrevTokens: (state, action) => {
      state.customers.tidbMigrationGroups.prevTokens = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  handleTidbMigrationGroupsDisplay,
  handleTidbMigrationGroupsDisplayNextToken,
  handleTidbMigrationGroupsDisplayLimit,
  handleTidbMigrationGroupsDisplayShowingSearchResults,
  handleTidbMigrationGroupsDisplaySearchTerm,
  handleTidbMigrationGroupsDisplayPrevTokens,
  handleTidbMigrationGroupsDisplayLoading,
  handleAccountsDetails,
  handleInventories,
  handleUpgradeTrackingResponse,
  handleReleases,
  handleUnsetEnvDetails,
  handleUnsetCustomerDetails,
  handleUnsetTypeDetails,
  handleCustomersList,
  handleSeachTerm,
  handleSearchParam,
  handleSearchTotal,
  handleShowingSearchResults,
  handleSelectedCustomers,
  handlePageLimit,
  handleSkip,
  handleLoading,
  handleActiveCustomer,
  handleStatusFilter,
  handleCustomerCount,
  setCurrentCustomer,
  setNewCustomerConfiguration,
  setToggleCustomerConfigurationSaveButton,
  decrement,
  incrementByAmount,
  handleEnvChange,
  handleMonitoringChange,
  handleCustomerDetails,
  handleCustomerTrackingIds,
  handleCustomerCredentials,
  handleCustomerLicense,
  handleMatchingOrgs,
  handleOpenSearchShards,
  handleExternalIntegration,
  handleIndicesGet,
  handleApiConnections,
  handleDataSyncStatus,
  handleOrphanIndicesGet,
  handleUnsetOpenSearchDetails,
  handleUnsetMonitoringEnv,
  handleTidbDatabases,
  handleTidbInfraUpgrades,
  handleLastLoggedIn,
  handleDormantOrgs,
  handleUnsetFeatureFlags,
  handleSetFeatureFlags,
  handleDeselectCustomersSelected,
  handleTidbMigrationNotificationMessage,
  handleMasterConfiguration,
  handleTidbDatabasesForMasterConfiguration,
  handleTidbMigrationConfigDataToDisplay,
  handleTidbMigrationCustomerInfo,
  handleForceUpdateSelectedCustomers,
  handleTidbMigrationDialogState,
  handleTidbMigrationGroupDetails,
  handleTidbMigrationCustomersDetails,
  handleTidbMigrationGroupCustomersDetails,
  handleTidbMigrationcustomerMigrationDetails,
  handleTidbMigrationcustomerValidationDetails,
  handleTidbMigrationGroupDetailsMigrationCompleted
} = rlsSlice.actions;

export default rlsSlice.reducer;
