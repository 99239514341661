/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRlsOrgAnalytics = /* GraphQL */ `
  mutation CreateRlsOrgAnalytics(
    $input: CreateRlsOrgAnalyticsInput!
    $condition: ModelRlsOrgAnalyticsConditionInput
  ) {
    createRlsOrgAnalytics(input: $input, condition: $condition) {
      id
      customerId
      date
      time
      organizationId
      organizationFriendlyId
      environmentCode
      activeUsers
      inActiveUsers
      deletedUsers
      roles
      apiConnections
      customerCodes
      trustedApis
      trustedObjects
      apiResources
      objects
      categories
      schedulers
      dataSyncStatus
      salesforceConnectionStatus
      platformConnectionStatus
      postgresConnectionStatus
      environment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRlsOrgAnalytics = /* GraphQL */ `
  mutation UpdateRlsOrgAnalytics(
    $input: UpdateRlsOrgAnalyticsInput!
    $condition: ModelRlsOrgAnalyticsConditionInput
  ) {
    updateRlsOrgAnalytics(input: $input, condition: $condition) {
      id
      customerId
      date
      time
      organizationId
      organizationFriendlyId
      environmentCode
      activeUsers
      inActiveUsers
      deletedUsers
      roles
      apiConnections
      customerCodes
      trustedApis
      trustedObjects
      apiResources
      objects
      categories
      schedulers
      dataSyncStatus
      salesforceConnectionStatus
      platformConnectionStatus
      postgresConnectionStatus
      environment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRlsOrgAnalytics = /* GraphQL */ `
  mutation DeleteRlsOrgAnalytics(
    $input: DeleteRlsOrgAnalyticsInput!
    $condition: ModelRlsOrgAnalyticsConditionInput
  ) {
    deleteRlsOrgAnalytics(input: $input, condition: $condition) {
      id
      customerId
      date
      time
      organizationId
      organizationFriendlyId
      environmentCode
      activeUsers
      inActiveUsers
      deletedUsers
      roles
      apiConnections
      customerCodes
      trustedApis
      trustedObjects
      apiResources
      objects
      categories
      schedulers
      dataSyncStatus
      salesforceConnectionStatus
      platformConnectionStatus
      postgresConnectionStatus
      environment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRlsEnvAnalytics = /* GraphQL */ `
  mutation CreateRlsEnvAnalytics(
    $input: CreateRlsEnvAnalyticsInput!
    $condition: ModelRlsEnvAnalyticsConditionInput
  ) {
    createRlsEnvAnalytics(input: $input, condition: $condition) {
      id
      environment
      date
      time
      environmentCode
      activeCustomersCount
      inActiveCustomersCount
      idpCustomersCount
      nonIdpCustomersCount
      trialOrgCount
      nonTrialOrgCount
      accountsCount
      releasesCount
      licenses
      accounts
      availableShards
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRlsEnvAnalytics = /* GraphQL */ `
  mutation UpdateRlsEnvAnalytics(
    $input: UpdateRlsEnvAnalyticsInput!
    $condition: ModelRlsEnvAnalyticsConditionInput
  ) {
    updateRlsEnvAnalytics(input: $input, condition: $condition) {
      id
      environment
      date
      time
      environmentCode
      activeCustomersCount
      inActiveCustomersCount
      idpCustomersCount
      nonIdpCustomersCount
      trialOrgCount
      nonTrialOrgCount
      accountsCount
      releasesCount
      licenses
      accounts
      availableShards
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRlsEnvAnalytics = /* GraphQL */ `
  mutation DeleteRlsEnvAnalytics(
    $input: DeleteRlsEnvAnalyticsInput!
    $condition: ModelRlsEnvAnalyticsConditionInput
  ) {
    deleteRlsEnvAnalytics(input: $input, condition: $condition) {
      id
      environment
      date
      time
      environmentCode
      activeCustomersCount
      inActiveCustomersCount
      idpCustomersCount
      nonIdpCustomersCount
      trialOrgCount
      nonTrialOrgCount
      accountsCount
      releasesCount
      licenses
      accounts
      availableShards
      createdAt
      updatedAt
      __typename
    }
  }
`;

// export const createRlsOrgData = /* GraphQL */ `
//   mutation CreateRlsOrgData(
//     $input: input!
//     $condition: ModelRlsOrgDataConditionInput
//   ) {
//     CreateRlsOrgData(input: $input, condition: $condition) {
//       id
//       customerId
//       organizationId
//       organizationFriendlyId
//       environmentCode
//       offboardingRequested
//       offboardingRequestedBy
//       offboardingStatus
//       environment
//       __typename
//     }
//   }
// `;

export const createRlsOrgData = /* GraphQL */ `
  mutation CreateRlsOrgData($input: CreateRlsOrgDataInput!) {
    createRlsOrgData(input: $input) {
      id
      customerId
      organizationId
      organizationFriendlyId
      environmentCode
      offboardingRequested
      offboardingRequestedBy
      offboardingStatus
      environment
    }
  }
`;

export const updateRlsOrgData = /* GraphQL */ `
  mutation UpdateRlsOrgData($input: UpdateRlsOrgDataInput!) {
    updateRlsOrgData(input: $input) {
      id
      customerId
      organizationId
      organizationFriendlyId
      environmentCode
      offboardingRequested
      offboardingRequestedBy
      offboardingStatus
      environment
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      username
      email
      firstTimeLogedIn
      firstTimeRequestedAccessControl
      accessControlApplied
    }
  }
`;

export const createRequestedAccessControl = /* GraphQL */ `
  mutation CreateRequestedAccessControl(
    $input: CreateRequestedAccessControlInput!
  ) {
    createRequestedAccessControl(input: $input) {
      userId
      requestDetails
      created
    }
  }
`;

export const deleteRequestAccessControl = /* GraphQL */ `
  mutation DeleteRequestedAccessControl(
    $input: DeleteRequestedAccessControlInput!
  ) {
    deleteRequestedAccessControl(input: $input) {
      userId
      requestDetails
      created
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      email
      firstTimeRequestedAccessControl
      firstTimeLogedIn
      accessControlApplied
    }
  }
`;

export const createAppliedAccessControl = /* GraphQL */ `
  mutation CreateAppliedAccessControl(
    $input: CreateAppliedAccessControlInput!
  ) {
    createAppliedAccessControl(input: $input) {
      userId
      accessDetails
      created
    }
  }
`;

export const updateAppliedAccessControl = /* GraphQL */ `
  mutation UpdateAppliedAccessControl(
    $input: UpdateAppliedAccessControlInput!
  ) {
    updateAppliedAccessControl(input: $input) {
      userId
      accessDetails
      created
    }
  }
`;

export const createTidbMigrationGroup = /* GraphQL */ `
  mutation CreateTidbMigrationGroup($input: CreateTidbMigrationGroupInput!) {
    createTidbMigrationGroup(input: $input) {
      id
      environment
      tidbDatabaseName
      infraId
      isStandaloneDatabase
      createdAt
      createdBy
      migrationCompletedOn
      status
      notificationConfiguration {
        sendNotificationPriorToMigration
        sendNotificationAfterMigration
        sendNotificationPriorToDataValidation
        sendNotificationAfterDataValidation
        notificationUserbase
      }
    }
  }
`;

export const createTidbMigrationCustomerMutation = /* GraphQL */ `
  mutation CreateTidbMigrationCustomer(
    $input: CreateTidbMigrationCustomerInput!
  ) {
    createTidbMigrationCustomer(input: $input) {
      customerId
      groupId
      orgFid
      orgId
      migrationStatus
      environment
      currentInstanceURL
      forceMigration
      sendNotificationPriorToMigration {
        state
        startedAt
        completedAt
      }
      sendNotificationAfterMigration {
        state
        startedAt
        completedAt
      }
      sendNotificationPriorToDataValidation {
        state
        startedAt
        completedAt
      }
      sendNotificationAfterDataValidation {
        state
        startedAt
        completedAt
      }
      datamigrationStatus {
        state
        startedAt
        completedAt
        trackingId
      }
      dataValidationAndSwitchOverStatus {
        state
        startedAt
        completedAt
        trackingId
      }
    }
  }
`;

export const updateTidbMigrationCustomer = /* GraphQL */ `
  mutation UpdateTidbMigrationCustomer(
    $input: UpdateTidbMigrationCustomerInput!
  ) {
    updateTidbMigrationCustomer(input: $input) {
      id
      groupId
      customerId
      orgFid
      orgId
      migrationStatus
      currentInstanceURL
      forceMigration
      sendNotificationPriorToMigration {
        state
        startedAt
        completedAt
      }
      sendNotificationAfterMigration {
        state
        startedAt
        completedAt
      }
      sendNotificationPriorToDataValidation {
        state
        startedAt
        completedAt
      }
      sendNotificationAfterDataValidation {
        state
        startedAt
        completedAt
      }
      datamigrationStatus {
        state
        startedAt
        completedAt
        trackingId
      }
      dataValidationAndSwitchOverStatus {
        state
        startedAt
        completedAt
        trackingId
      }
      environment
    }
  }
`;

export const updateTidbMigrationGroup = /* GraphQL */ `
  mutation UpdateTidbMigrationGroup($input: UpdateTidbMigrationGroupInput!) {
    updateTidbMigrationGroup(input: $input) {
      id
      environment
      tidbDatabaseName
      infraId
      isStandaloneDatabase
      createdAt
      createdBy
      migrationCompletedOn
      status
      notificationConfiguration {
        sendNotificationPriorToMigration
        sendNotificationAfterMigration
        sendNotificationPriorToDataValidation
        sendNotificationAfterDataValidation
        notificationUserbase
      }
    }
  }
`;

export const deleteTidbMigrationCustomer = /* GraphQL */ `
  mutation DeleteTidbMigrationCustomer(
    $input: DeleteTidbMigrationCustomerInput!
  ) {
    deleteTidbMigrationCustomer(input: $input) {
      id
    }
  }
`;

export const updateTurboHealthCheck = /* GraphQL */ `
  mutation UpdateTurboHealthCheck(
    $id: ID!
    $tenantId: String!
    $environmentId: String!
    $lastCheckedOn: AWSDateTime
    $callVictorops: Boolean
    $healthCheckInterval: Int
    $disableIntegrationHealthCheck: Boolean
    $disableAdminHealthCheck: Boolean
    $disablePricingHealthCheck: Boolean
  ) {
    updateTurboHealthChecks(
      input: {
        id: $id
        tenantId: $tenantId
        environmentId: $environmentId
        lastCheckedOn: $lastCheckedOn
        callVictorops: $callVictorops
        healthCheckInterval: $healthCheckInterval
        disableIntegrationHealthCheck: $disableIntegrationHealthCheck
        disableAdminHealthCheck: $disableAdminHealthCheck
        disablePricingHealthCheck: $disablePricingHealthCheck
      }
    ) {
      id
      tenantId
      environmentId
      lastCheckedOn
      dataIntegrationHealthStatus
      dataAdminHealthStatus
      pricingHealthStatus
      dataIntegrationHealthResponse
      dataAdminHealthResponse
      pricingHealthResponse
      callVictorops
      healthCheckInterval
      disableIntegrationHealthCheck
      disableAdminHealthCheck
      disablePricingHealthCheck
    }
  }
`;
