const tokenOptions = [
  {
    value: 'cm_token',
    text: 'Customer Management Token'
  },
  {
    value: 'gt_token',
    text: 'Global Trust token',
    boolean: false
  },
  {
    value: 'at_token',
    text: 'App trust token',
    boolean: false
  },
  {
    value: 'sign_provisioning_token',
    text: 'Sign provisioning token',
    boolean: false
  }
];

export default tokenOptions;
