import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  alpha,
  lighten,
  useTheme,
  Dialog,
  CardContent,
  CardActions,
  Card,
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Avatar,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Outlet } from 'react-router-dom';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

import { generateClient } from 'aws-amplify/api';
import {
  createRequestedAccessControl,
  updateUser
} from 'src/graphql/mutations';
import {
  setUserData,
  setAccessFormOpen,
  setRequestedModifyAccessControl
} from 'src/store/reducers/userCustomData';

import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

import { rlsEnvDetails } from 'src/data/Rls';

import Sidebar from './Sidebar';
import Header from './Header';

import emailTemplates from 'src/data/emails/emailTemplates';

import { invokeNotification } from 'src/store/reducers/notification';

const RequestAccessDialogComponent = ({ dialogState, environmentDetails }) => {
  const user = useSelector((state) => state.user);
  const userCustomData = useSelector((state) => state.userCustomData);
  const dispatch = useDispatch();

  const [environments, setEnvironments] = useState([]);
  const [originalEnvironments, setOriginalEnvironments] = useState([]);
  const [loading, setLoading] = useState({
    state: false,
    message: 'Please wait ...',
    atLeastOneClicked: false
  });

  useEffect(() => {
    if (userCustomData.requestedModifyAccessControl) {
      const oldEnvironments = new Set(
        userCustomData.accessControl.map((env) => env.name)
      );

      const newAccessControl = [];

      environmentDetails.forEach((env) => {
        if (oldEnvironments.has(env.name)) {
          newAccessControl.push(
            userCustomData.accessControl.find(
              (access) => access.name === env.name
            )
          );
        } else {
          newAccessControl.push(env);
        }
      });

      setEnvironments(newAccessControl);
      setOriginalEnvironments(newAccessControl);
    } else {
      setEnvironments(environmentDetails);
      setOriginalEnvironments(environmentDetails);
    }
  }, [environmentDetails]);

  const handleAccessControlCheckbox = (checked, checkName, environment) => {
    const access = {
      read: false,
      write: false,
      admin: false
    };
    const updatedEnvironments = environments.map((env) => {
      if (env.name === environment.name) {
        return {
          ...env,
          access: {
            ...access,
            [checkName]: checked
          }
        };
      }
      return env;
    });

    // check if access control is not matching original access control. if not set flag of update to true. if they are matching set it to false
    let updateFlag = false;
    updatedEnvironments.forEach((env) => {
      const originalEnv = originalEnvironments.find(
        (originalEnv) => originalEnv.name === env.name
      );
      if (
        originalEnv.access.read !== env.access.read ||
        originalEnv.access.write !== env.access.write ||
        originalEnv.access.admin !== env.access.admin
      ) {
        updateFlag = true;
      }
    });
    if (updateFlag) {
      setLoading({
        state: false,
        message: 'Please wait ...',
        atLeastOneClicked: true
      });
    } else {
      setLoading({
        state: false,
        message: 'Please wait ...',
        atLeastOneClicked: false
      });
    }

    setEnvironments(updatedEnvironments);
  };

  const sendEmail = async (emailTemplate) => {
    try {
      const iss =
        user.signInUserSession.tokens.accessToken.payload.iss.split('/');
      const authToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      const apiResponse = post({
        apiName: 'congaOpsPortalRestApi',
        path: '/utility/sendGrid/sendEmail',
        options: {
          body: {
            userPoolId: iss[iss.length - 1].trim(),
            region: 'us-west-2',
            from: emailTemplate.from,
            to: emailTemplate.to,
            cc: emailTemplate.cc || [],
            subject: emailTemplate.subject,
            html: emailTemplate.html
          },
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      });
      const { body } = await apiResponse.response;
      const response = await body.json();

      console.log('Email sent successfully', response);
    } catch (err) {
      console.log(err);
    }
  };

  const callRequestAccessControl = async (modifyAccessControl) => {
    setLoading({
      state: true,
      message: 'Requesting Access Control ...',
      atLeastOneClicked: true
    });
    const client = generateClient();
    try {
      const input = {
        userId: user.attributes.sub,
        requestDetails: JSON.stringify(environments),
        created: true
      };

      const createResponse = await client.graphql({
        query: createRequestedAccessControl,
        variables: {
          input
        }
      });
      if (createResponse.data.createRequestedAccessControl.userId) {
        console.log('Request created successfully');
        if (modifyAccessControl) {
          const emailTemplateConfirmationToUser =
            emailTemplates.modifyAccessControlConfirmation({
              userName: user.attributes.name
            });
          const emailTemplateReminderToAdminTeam =
            emailTemplates.notifyCloudopsTeamForModifiedRequest({
              userName: user.attributes.name
            });

          await sendEmail({
            ...emailTemplateConfirmationToUser,
            to: [user.attributes.email, ...emailTemplateConfirmationToUser.to]
          });
          await sendEmail({
            ...emailTemplateReminderToAdminTeam,
            to: [...emailTemplateReminderToAdminTeam.to]
          });
          dispatch(setAccessFormOpen(false));
          dispatch(setRequestedModifyAccessControl(false));
          dispatch(
            invokeNotification({
              duration: 5,
              title: `Access Control Request Sent`,
              type: 'success',
              description: `Your access control request has been sent successfully. You will get a email notification once your request is processed`
            })
          );
        } else {
          const updateUserResponse = await client.graphql({
            query: updateUser,
            variables: {
              input: {
                id: user.attributes.sub,
                username: user.attributes.name,
                email: user.attributes.email,
                firstTimeRequestedAccessControl: true
              }
            }
          });
          if (updateUserResponse.data.updateUser.id) {
            console.log('User updated successfully');

            dispatch(
              setUserData({
                ...userCustomData.data,
                firstTimeRequestedAccessControl: true
              })
            );

            const emailTemplateConfirmationToUser =
              emailTemplates.requestAccessControlConfirmation({
                userName: user.attributes.name
              });
            const emailTemplateReminderToAdminTeam =
              emailTemplates.requestAccessControlNotificationToCloudopsTeam({
                userName: user.attributes.name
              });

            await sendEmail({
              ...emailTemplateConfirmationToUser,
              to: [user.attributes.email, ...emailTemplateConfirmationToUser.to]
            });
            await sendEmail({
              ...emailTemplateReminderToAdminTeam,
              to: [...emailTemplateReminderToAdminTeam.to]
            });
          }
        }
      }
    } catch (error) {
      console.error('Error creating request:', error);
    }
    setLoading({
      state: false,
      message: 'Please wait ...',
      atLeastOneClicked: false
    });
  };
  return (
    <Dialog
      open={dialogState}
      onClose={() => {
        if (userCustomData.requestedModifyAccessControl) {
          dispatch(setAccessFormOpen(false));
          dispatch(setRequestedModifyAccessControl(false));
        }
      }}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '900px',
          maxHeight: '100%',
          overflow: 'auto'
        }
      }}
    >
      <Card sx={{ maxHeight: '100%', overflow: 'auto' }}>
        {userCustomData.data?.firstTimeRequestedAccessControl !== null &&
          userCustomData.data?.firstTimeRequestedAccessControl === true &&
          userCustomData.requestedModifyAccessControl === false && (
            <>
              <CardHeader
                subheader={`You have already requested access to the application. Cloudops team will work on your access request. You will get a email notification once your request is processed`}
                title="You already Requested 🔐"
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    p: 4
                  }}
                >
                  <img
                    alt="Request Sent"
                    height={300}
                    src="/static/images/illustrations/requestSent.svg"
                  />
                  <Typography align="center">
                    You have already requested access to the application.
                    Cloudops team will work on your access request. You will get
                    a email notification once your request is processed
                  </Typography>
                </Box>
              </CardContent>
            </>
          )}
        {userCustomData.data?.accessControlApplied !== null &&
          userCustomData.data?.accessControlApplied === false &&
          !userCustomData.data?.firstTimeLogedIn &&
          userCustomData.requestedModifyAccessControl === false && (
            <>
              <CardHeader
                subheader={`Your access control is not yet applied. Please reach out to cloudops team`}
                title="You Don't have any access Control applied 🔐"
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    p: 4
                  }}
                >
                  <img
                    alt="Request Sent"
                    height={300}
                    src="/static/images/illustrations/no-access.svg"
                  />
                  <Typography align="center">
                    Your access control is not yet applied yet. Please reach to
                    cloudops team to get access
                  </Typography>
                </Box>
              </CardContent>
            </>
          )}

        {((userCustomData.data?.firstTimeLogedIn !== null &&
          userCustomData.data?.firstTimeLogedIn &&
          userCustomData.data?.firstTimeRequestedAccessControl !== true) ||
          userCustomData.requestedModifyAccessControl === true) && (
          <>
            <CardHeader
              subheader={`${
                userCustomData.requestedModifyAccessControl
                  ? 'You can requested to modify your access control. Cloudops team will work on your access request. You will get a email notification once your request is processed'
                  : 'You are not authorized to access this application. Please request access to the application which u think u need access to ? Cloudops team will work on your access request. You will get a email notification once your request is processed'
              }`}
              title={
                userCustomData.requestedModifyAccessControl
                  ? 'Update Your Access 🔐'
                  : 'Request Access 🔐'
              }
            />
            <Divider />
            <CardContent sx={{ p: 0 }}>
              {' '}
              <TableContainer sx={{ maxHeight: 720 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Enviroment Name</TableCell>
                      <TableCell align="center">Product</TableCell>
                      <TableCell align="center">Access Control</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {environments
                      .filter((env) => env.name !== 'cloudops-portal')
                      .map((env) => (
                        <TableRow hover key={env?.name}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar
                                variant="rounded"
                                alt={env.name}
                                src={`https://api.dicebear.com/7.x/rings/svg?seed=${env.name}`}
                              />

                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                sx={{ ml: 2 }}
                              >
                                {env.displayName}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              align="center"
                            >
                              {env.product}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'row',
                              flexWrap: 'nowrap'
                            }}
                          >
                            <FormGroup
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                flexWrap: 'nowrap'
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="success"
                                    checked={env.access.read}
                                    onChange={(event) =>
                                      handleAccessControlCheckbox(
                                        event.target.checked,
                                        'read',
                                        env
                                      )
                                    }
                                  />
                                }
                                label="Read"
                                sx={{ mr: 2 }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="warning"
                                    checked={env.access.write}
                                    onChange={(event) =>
                                      handleAccessControlCheckbox(
                                        event.target.checked,
                                        'write',
                                        env
                                      )
                                    }
                                  />
                                }
                                label="Write"
                                sx={{ mr: 2 }}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="error"
                                    checked={env.access.admin}
                                    onChange={(event) =>
                                      handleAccessControlCheckbox(
                                        event.target.checked,
                                        'admin',
                                        env
                                      )
                                    }
                                  />
                                }
                                label="Admin"
                                sx={{ mr: 2 }}
                              />
                            </FormGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Divider />
            <CardActions
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 2,
                pb: 2
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<LockTwoToneIcon />}
                loading={loading.state}
                loadingPosition="end"
                loadingMessage={loading.message}
                disabled={!loading.atLeastOneClicked || loading.state}
                onClick={() =>
                  callRequestAccessControl(
                    userCustomData.requestedModifyAccessControl
                  )
                }
              >
                {userCustomData.requestedModifyAccessControl
                  ? 'Request Update Access'
                  : ' Request Access'}
              </LoadingButton>
            </CardActions>
          </>
        )}
      </Card>
    </Dialog>
  );
};

const getAllEnvironments = () => {
  let environments = [];

  environments = rlsEnvDetails.map((env) => {
    return {
      name: `${env.pageLink}-${env.resourceCode}`,
      product: 'RLS',
      displayName: `${env.displayName.toLocaleUpperCase()} - (${
        env.resourceCode
      })`,
      access: {
        read: false,
        write: false,
        admin: false
      }
    };
  });
  const turbo = {
    name: 'turbo',
    product: 'Turbo',
    displayName: 'Turbo',
    access: {
      read: false,
      write: false,
      admin: false
    }
  };
  const cloudopsPortal = {
    name: 'cloudops-portal',
    product: 'Cloudops',
    displayName: 'Cloudops Portal',
    access: {
      read: false,
      write: false,
      admin: false
    }
  };

  return [...environments, turbo, cloudopsPortal];
};

const SidebarLayout = () => {
  const theme = useTheme();
  const userCustomData = useSelector((state) => state.userCustomData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAccessFormOpen(
        userCustomData?.data?.firstTimeLogedIn !== null
          ? userCustomData?.data?.firstTimeLogedIn
          : false
      )
    );
    if (
      userCustomData?.data?.accessControlApplied !== null &&
      userCustomData?.data?.accessControlApplied === false
    ) {
      dispatch(setAccessFormOpen(true));
    }
  }, [userCustomData?.data]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
        <RequestAccessDialogComponent
          dialogState={userCustomData.accessFormOpen}
          environmentDetails={getAllEnvironments()}
        />
      </Box>
    </>
  );
};

export default SidebarLayout;
