import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  internalStatusPage: {
    components: {
      data: [],
      loading: false,
      loadingMessage: 'Loading...'
    }
  }
};

export const statusPageSlice = createSlice({
  name: 'statusPage',
  initialState,
  reducers: {
    setAllComponents: (state, action) => {
      state.internalStatusPage.components.loading = action.payload.loading;
      state.internalStatusPage.components.loadingMessage =
        action.payload.loadingMessage;
      state.internalStatusPage.components.data = action.payload.data;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllComponents } = statusPageSlice.actions;

export default statusPageSlice.reducer;
