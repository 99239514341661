const hasAccess = (
  envName,
  accessControl,
  accessLevelValid = ['read', 'write', 'admin']
) => {
  if (
    accessControl === null ||
    accessControl === undefined ||
    accessControl.length === 0
  )
    return false;
  const matchingEnv = accessControl.find((env) => env.name === envName);

  if (matchingEnv === undefined || matchingEnv === null) return false;

  if (matchingEnv?.access === undefined || matchingEnv?.access === null) {
    return false;
  }

  let accessType = null;

  Object.keys(matchingEnv.access).forEach((key) => {
    if (matchingEnv.access[key] === true) {
      accessType = key;
    }
  });

  if (accessType === null) return false;

  if (accessLevelValid.includes(accessType)) return true;

  return false;
};

export default hasAccess;
