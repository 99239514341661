const groups = [
  {
    id: '45e6ae74-ff6f-4ee5-967c-4caaff50e6f9',
    name: 'ops-portal-admin',
    role: 'admin',
    displayName: 'Admin',
    priority: 0,
    color: 'error'
  },
  {
    id: '5ff05f19-7422-41ad-a7c3-c881abeb0693',
    name: 'ops-portal-write',
    role: 'write',
    displayName: 'Write',
    priority: 1,
    color: 'warning'
  },
  {
    id: '873b8d0a-d93f-4e8b-b3bb-27300e3b54e5',
    name: 'ops-portal-read-only',
    role: 'read',
    displayName: 'Read Only',
    priority: 2,
    color: 'success'
  }
];

export default groups;
