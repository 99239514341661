const requestAccessControlConfirmation = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [],
    cc: [],
    subject: `Access Control Request Received On ops portal`,
    html: `
          <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Access Control Request Received On ops portal</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f2f2f2;
                margin: 0;
                padding: 20px;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #ffffff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
            }
            .header {
                text-align: center;
                margin-bottom: 20px;
            }
            .header img {
                max-width: 100px;
                margin-bottom: 20px;
            }
            .header h3 {
                color: #333;
                margin: 0;
            }
            .content p {
                font-size: 16px;
                line-height: 1.6;
                color: #555;
            }
            .content p strong {
                color: #333;
            }
            .footer p {
                font-size: 14px;
                line-height: 1.4;
                color: #999;
                text-align: center;
                margin: 20px 0 0;
            }
        </style>
    </head>
    <body>

        <div class="container">
            <div class="header">
                <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                <h3>Access Control Request Received On ops portal</h3>
            </div>
            <div class="content">
                <p>Dear <strong>${data.userName}</strong>,</p>
                <p>Thank you for submitting your access control request. We have received your request and it is currently being processed by cloudops team.</p>
                <p>You will receive another email once your request has been approved or rejected. Please feel free to contact us if you have any questions or concerns.</p>
                <p>Best regards,<br>Conga Cloudops Team</p>
            </div>
            <div class="footer">
                <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                <p>This portal is only for internal use. Please don't share any information of ops portal to a external user.</p>
            </div>
        </div>

    </body>
    </html>
        `
  };
};
const modifyAccessControlConfirmation = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [],
    cc: [],
    subject: `Modification Request Received On ops portal`,
    html: `
          <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Modification Request Received On ops portal</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f2f2f2;
                margin: 0;
                padding: 20px;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #ffffff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
            }
            .header {
                text-align: center;
                margin-bottom: 20px;
            }
            .header img {
                max-width: 100px;
                margin-bottom: 20px;
            }
            .header h3 {
                color: #333;
                margin: 0;
            }
            .content p {
                font-size: 16px;
                line-height: 1.6;
                color: #555;
            }
            .content p strong {
                color: #333;
            }
            .footer p {
                font-size: 14px;
                line-height: 1.4;
                color: #999;
                text-align: center;
                margin: 20px 0 0;
            }
        </style>
    </head>
    <body>

        <div class="container">
            <div class="header">
                <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                <h3>Modification Request Received On ops portal</h3>
            </div>
            <div class="content">
                <p>Dear <strong>${data.userName}</strong>,</p>
                <p>Thank you for submitting your modification request for access control. Your request is being processed by the cloudops team.</p>
                <p>You will receive another email once your request has been reviewed and processed. Please feel free to contact us if you have any questions or concerns.</p>
                <p>Best regards,<br>Conga Cloudops Team</p>
            </div>
            <div class="footer">
                <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                <p>This portal is only for internal use. Please don't share any information of ops portal to a external user.</p>
            </div>
        </div>

    </body>
    </html>
        `
  };
};

const requestAccessControlNotificationToCloudopsTeam = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: ['Cloud-Ops-All-DL@conga.com'], // Add the recipient email addresses here
    subject: `Reminder: Access Control Request Received on OPS Portal`,
    cc: [],
    html: `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Reminder: Access Control Request Received on OPS Portal</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f2f2f2;
                    margin: 0;
                    padding: 20px;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .header img {
                    max-width: 100px;
                    margin-bottom: 20px;
                }
                .header h3 {
                    color: #333;
                    margin: 0;
                }
                .content p {
                    font-size: 16px;
                    line-height: 1.6;
                    color: #555;
                }
                .content p strong {
                    color: #333;
                }
                .footer p {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #999;
                    text-align: center;
                    margin: 20px 0 0;
                }
            </style>
        </head>
        <body>
  
            <div class="container">
                <div class="header">
                    <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                    <h3>Reminder: Access Control Request Received on OPS Portal</h3>
                </div>
                <div class="content">
                    <p>Dear Cloudops Team,</p>
                    <p>This is a reminder that an access control request has been submitted by <strong>${
                      data.userName
                    }</strong>.</p>
                    <p>Please review and process the request as soon as possible. You can view all requested access control details by clicking the link below:</p>
                    <p><a href="https://ops-portal.congacloud.com/#/cloudops-portal/access-requests">View Access Control Requests</a></p>
                    <p>Best regards,<br>Conga Cloudops Team</p>
                </div>
                <div class="footer">
                    <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                    <p>This portal is only for internal use. Please don't share any information of the OPS portal with external users.</p>
                </div>
            </div>
  
        </body>
        </html>
      `
  };
};

const notifyCloudopsTeamForModifiedRequest = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: ['Cloud-Ops-All-DL@conga.com'], // Add the recipient email addresses here
    subject: `Action Required: Modified Access Control Request on OPS Portal`,
    cc: [],
    html: `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Action Required: Modified Access Control Request on OPS Portal</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f2f2f2;
                    margin: 0;
                    padding: 20px;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .header img {
                    max-width: 100px;
                    margin-bottom: 20px;
                }
                .header h3 {
                    color: #333;
                    margin: 0;
                }
                .content p {
                    font-size: 16px;
                    line-height: 1.6;
                    color: #555;
                }
                .content p strong {
                    color: #333;
                }
                .footer p {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #999;
                    text-align: center;
                    margin: 20px 0 0;
                }
            </style>
        </head>
        <body>
  
            <div class="container">
                <div class="header">
                    <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                    <h3>Action Required: Modified Access Control Request on OPS Portal</h3>
                </div>
                <div class="content">
                    <p>Dear Cloudops Team,</p>
                    <p>A modified access control request has been submitted by <strong>${
                      data.userName
                    }</strong>.</p>
                    <p>Please review and process the updated request as soon as possible. You can view all modified access control details by clicking the link below:</p>
                    <p><a href="https://ops-portal.congacloud.com/#/cloudops-portal/access-requests">View Access Control Requests</a></p>
                    <p>Best regards,<br>Conga Cloudops Team</p>
                </div>
                <div class="footer">
                    <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                    <p>This portal is only for internal use. Please don't share any information from the OPS portal with external users.</p>
                </div>
            </div>
  
        </body>
        </html>
      `
  };
};

const appliedAccessControlNotificationToUser = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [], // The email of the user who applied for access control
    subject: `Access Control Granted on OPS Portal`,
    cc: [],
    html: `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Access Control Granted on OPS Portal</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f2f2f2;
                    margin: 0;
                    padding: 20px;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .header img {
                    max-width: 100px;
                    margin-bottom: 20px;
                }
                .header h3 {
                    color: #333;
                    margin: 0;
                }
                .content p {
                    font-size: 16px;
                    line-height: 1.6;
                    color: #555;
                }
                .content p strong {
                    color: #333;
                }
                .footer p {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #999;
                    text-align: center;
                    margin: 20px 0 0;
                }
            </style>
        </head>
        <body>
  
            <div class="container">
                <div class="header">
                    <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                    <h3>Access Control Granted on OPS Portal</h3>
                </div>
                <div class="content">
                    <p>Dear <strong>${data.userName}</strong>,</p>
                    <p>We are pleased to inform you that your access control request has been approved. You now have access to the requested resources on the OPS portal.</p>
                    <p>If you have any questions or need further assistance, please feel free to contact us.</p>
                    <p>Best regards,<br>Conga Cloudops Team</p>
                </div>
                <div class="footer">
                    <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                    <p>This portal is only for internal use. Please don't share any information of the OPS portal with external users.</p>
                </div>
            </div>
  
        </body>
        </html>
      `
  };
};

const rejectedAccessControlNotificationToUser = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [], // The email of the user who applied for access control
    subject: `Access Control Request Rejected on OPS Portal`,
    cc: [],
    html: `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Access Control Request Rejected on OPS Portal</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #f2f2f2;
                    margin: 0;
                    padding: 20px;
                }
                .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .header img {
                    max-width: 100px;
                    margin-bottom: 20px;
                }
                .header h3 {
                    color: #333;
                    margin: 0;
                }
                .content p {
                    font-size: 16px;
                    line-height: 1.6;
                    color: #555;
                }
                .content p strong {
                    color: #333;
                }
                .footer p {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #999;
                    text-align: center;
                    margin: 20px 0 0;
                }
            </style>
        </head>
        <body>
  
            <div class="container">
                <div class="header">
                    <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                    <h3>Access Control Request Rejected on OPS Portal</h3>
                </div>
                <div class="content">
                    <p>Dear <strong>${data.userName}</strong>,</p>
                    <p>We regret to inform you that your access control request has been rejected. Unfortunately, you will not have access to the requested resources on the OPS portal at this time.</p>
                    <p>If you have any questions or need further assistance, please feel free to contact us.</p>
                    <p>Best regards,<br>Conga Cloudops Team</p>
                </div>
                <div class="footer">
                    <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                    <p>This portal is only for internal use. Please don't share any information of the OPS portal with external users.</p>
                </div>
            </div>
  
        </body>
        </html>
      `
  };
};

const accessControlModifiedNotificationToUser = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [], // Email of the user whose access control was modified
    subject: `Access Control Updated on OPS Portal`,
    cc: [],
    html: `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Access Control Updated on OPS Portal</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #f2f2f2;
                      margin: 0;
                      padding: 20px;
                  }
                  .container {
                      max-width: 600px;
                      margin: 0 auto;
                      background-color: #ffffff;
                      padding: 20px;
                      border-radius: 10px;
                      box-shadow: 0 0 10px rgba(0,0,0,0.1);
                  }
                  .header {
                      text-align: center;
                      margin-bottom: 20px;
                  }
                  .header img {
                      max-width: 100px;
                      margin-bottom: 20px;
                  }
                  .header h3 {
                      color: #333;
                      margin: 0;
                  }
                  .content p {
                      font-size: 16px;
                      line-height: 1.6;
                      color: #555;
                  }
                  .content p strong {
                      color: #333;
                  }
                  .footer p {
                      font-size: 14px;
                      line-height: 1.4;
                      color: #999;
                      text-align: center;
                      margin: 20px 0 0;
                  }
              </style>
          </head>
          <body>
    
              <div class="container">
                  <div class="header">
                      <img src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg" alt="Conga Logo">
                      <h3>Access Control Updated on OPS Portal</h3>
                  </div>
                  <div class="content">
                      <p>Dear <strong>${data.userName}</strong>,</p>
                      <p>We would like to inform you that your access control settings have been updated on the OPS portal. Please review the changes to ensure they meet your needs.</p>
                      <p>If you have any questions or need further assistance, please feel free to contact us.</p>
                      <p>Best regards,<br>Conga Cloudops Team</p>
                  </div>
                  <div class="footer">
                      <p>&copy; ${new Date().getFullYear()} Conga (OPS PORTAL). All rights reserved.</p>
                      <p>This portal is only for internal use. Please don't share any information of the OPS portal with external users.</p>
                  </div>
              </div>
    
          </body>
          </html>
        `
  };
};

const reminderToUsePlatformOrg = (data) => {
  return {
    from: `ops-portal@conga.com`,
    to: [data.adminUserEmail], // Add the recipient email addresses here
    cc: ['Cloud-Ops-All-DL@conga.com'],
    subject: `Reminder: Your Conga Platform Organization is Inactive`,
    html: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Reminder: Your Conga Platform Organization is Inactive</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          background-color: #f2f2f2;
          margin: 0;
          padding: 20px;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .header {
          text-align: center;
          margin-bottom: 20px;
        }
        .header img {
          max-width: 100px;
          margin-bottom: 20px;
        }
        .header h3 {
          color: #333;
          margin: 0;
        }
        .content p {
          font-size: 16px;
          line-height: 1.6;
          color: #555;
        }
        .content p strong {
          color: #333;
        }
        .button {
          display: inline-block;
          padding: 10px 20px;
          margin-top: 20px;
          font-size: 16px;
          color: #fff;
          background-color: #007bff;
          text-decoration: none;
          border-radius: 5px;
        }
        .details {
          margin-top: 20px;
          padding: 10px;
          background-color: #f9f9f9;
          border-radius: 5px;
        }
        .details p {
          margin: 5px 0;
          font-size: 14px;
          color: #333;
        }
        .footer p {
          font-size: 14px;
          line-height: 1.4;
          color: #999;
          text-align: center;
          margin: 20px 0 0;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="header">
          <img
            src="https://conga.com/themes/custom/themekit/images/branding/logo-conga-2022.svg"
            alt="Conga Logo"
          />
          <h3>Reminder: Your Conga Platform Organization is Inactive</h3>
        </div>
        <div class="content">
          <p>Dear <strong>${data.adminUserName}</strong>,</p>
          <p>
            We have noticed that your organization on the Conga platform has been
            inactive for the past two months. To keep your organization active,
            please log in to your account.
          </p>
          <div class="details">
            <p><strong>Organization Details:</strong></p>
            <p>Org ID: ${data.orgId}</p>
            <p>Org Friendly ID: ${data.orgFriendlyId}</p>
            <p>Customer ID: ${data.customerId}</p>
            <p>Platform Environment Name: ${data.environmentName}</p>
            <p><strong>Admin Information:</strong></p>
            <p>User ID: ${data.adminUserId}</p>
            <p>User Name: ${data.adminUserName}</p>
            <p>User Email: ${data.adminUserEmail}</p>
          </div>
          <a href="${data.loginUrl}" class="button"
            >Login to Conga Platform Org</a
          >
          <p>
            If no login activity is detected within the next month, your
            organization may be subject to inactivation and subsequent
            termination.
          </p>
          <p>
            If you have any questions or need further assistance, please feel free
            to contact us at Cloud-Ops-All-DL@conga.com
          </p>
          <p>Best regards,<br />Conga Cloudops Team</p>
        </div>
        <div class="footer">
          <p>
            &copy; ${new Date().getFullYear()} Conga Platform. All rights
            reserved.
          </p>
          <p>
            This notification is intended for the recipient's internal use only.
            Please do not share this information with external parties.
          </p>
        </div>
      </div>
    </body>
  </html>
  `
  };
};

export default {
  requestAccessControlConfirmation,
  modifyAccessControlConfirmation,
  requestAccessControlNotificationToCloudopsTeam,
  notifyCloudopsTeamForModifiedRequest,
  appliedAccessControlNotificationToUser,
  rejectedAccessControlNotificationToUser,
  accessControlModifiedNotificationToUser,
  reminderToUsePlatformOrg
};
