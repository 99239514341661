import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
// import useAuth from 'src/hooks/useAuth';
import { setUser, setUserLoading } from 'src/store/reducers/user';
import {
  setUserData,
  setAccessControl
} from 'src/store/reducers/userCustomData';
import { useSelector } from 'react-redux';
import signOutUser from 'src/content/pages/Auth/Comman/signout';

import Authenticated from './Authenticated';
import Login from 'src/content/pages/Auth/Login';

import {
  getUser as getUserFromDb,
  getAppliedAccessControl
} from './graphql/queries';
import { createUser } from './graphql/mutations';

import userGroups from 'src/data/groups';

import { Hub } from 'aws-amplify/utils';
import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
  deleteUser
} from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { store } from './store/store.js';

import Notification from './components/Notification';

import amplifyconfig from 'src/amplifyconfiguration.json';

const modifiedConfig = {
  ...amplifyconfig,
  oauth: {
    ...amplifyconfig.oauth,
    redirectSignIn: String(window.location.origin + window.location.pathname),
    redirectSignOut: String(window.location.origin + window.location.pathname)
  },
  signInRedirectFromConfig: String(
    window.location.origin + window.location.pathname
  ),
  signInRedirectFromWindow: String(
    window.location.origin + window.location.pathname
  )
};

Amplify.configure(modifiedConfig);

const App = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getUser();
  }, []);

  Hub.listen('auth', async ({ payload }) => {
    // eslint-disable-next-line default-case
    switch (payload.event) {
      case 'signInWithRedirect':
        // eslint-disable-next-line no-case-declarations
        break;
      case 'signedIn':
        getUser();
        break;
      case 'signInWithRedirect_failure':
        // handle sign in failure
        break;
    }
  });

  useEffect(() => {
    const checkAndCreateUser = async () => {
      const client = generateClient();
      try {
        const userId = user.attributes.sub;

        // Step 1: Check if the user already exists
        const userData = await client.graphql({
          query: getUserFromDb,
          variables: { id: userId }
        });

        if (!userData.data.getUser) {
          // Step 2: Try to create the user
          try {
            const createResponse = await client.graphql({
              query: createUser,
              variables: {
                input: {
                  id: userId,
                  username: user.attributes.name,
                  email: user.attributes.email,
                  accessControlApplied: false,
                  firstTimeLogedIn: true,
                  firstTimeRequestedAccessControl: false
                }
              }
            });

            store.dispatch(setUserData(createResponse?.data?.createUser));

            console.log('User record created successfully');
          } catch (error) {
            const knownError = error?.errors.filter(
              (error) => error.errorType === 'ConditionalCheckFailedException'
            );
            if (knownError.length > 0) {
              console.log('User record already exists');
            } else {
              console.error('Error creating user record:', error);
            }
          }
        } else {
          console.log('User record already exists');
          store.dispatch(setUserData(userData?.data?.getUser));
          if (userData?.data?.getUser?.accessControlApplied) {
            const appliedAccessControl = await client.graphql({
              query: getAppliedAccessControl,
              variables: { userId: userId }
            });
            if (appliedAccessControl.data.getAppliedAccessControl?.created) {
              const parsedAccessControl = await JSON.parse(
                appliedAccessControl?.data?.getAppliedAccessControl
                  ?.accessDetails
              );
              store.dispatch(setAccessControl(parsedAccessControl));
            }
          }
        }
      } catch (error) {
        console.error('Error checking or creating user record:', error);
      }
    };

    if (user && user.attributes && user.attributes.sub) {
      checkAndCreateUser();
    }
  }, [user.attributes.sub]);

  const getUser = async () => {
    store.dispatch(setUserLoading(true));
    try {
      const user = await getCurrentUser();
      if (user.userId) {
        let currentUserSession = await fetchAuthSession();
        const currentTime = Math.floor(new Date().getTime() / 1000);
        const accessTokenExpiration =
          currentUserSession.tokens.accessToken.payload.exp;

        if (accessTokenExpiration < currentTime) {
          console.log('Access token has expired.');
          try {
            currentUserSession = await fetchAuthSession({
              forceRefresh: true
            });
          } catch (err) {
            signOutUser(
              'Error occured while refreshing access token',
              err.message || 'please relogin again.'
            );
            console.log(`ERROR while refreshing access token ${err}`);
            throw new Error(err);
          }
        }
        const userAttributes = await fetchUserAttributes();

        let groups = [];
        let matchingGroups = [];

        if (userAttributes['custom:groups']) {
          groups = JSON.parse(userAttributes['custom:groups']);

          matchingGroups = userGroups.filter((group) =>
            groups.includes(group.id)
          );

          if (!matchingGroups || matchingGroups?.length === 0) {
            await deleteUser();
            signOutUser(
              'User Should be part of a group',
              'Raise a IT request to add your conga.com user to ops portal'
            );
          }

          if (matchingGroups.length > 1) {
            matchingGroups = matchingGroups.sort(
              (group1, group2) => group2.priority - group1.priority
            );
          }
        } else {
          await deleteUser();
          signOutUser(
            'User Should be part of a group',
            'Raise a IT request to add your conga.com user to ops portal'
          );
        }

        currentUserSession.tokens.accessToken.toString =
          currentUserSession.tokens.accessToken.toString();
        currentUserSession.tokens.idToken.toString =
          currentUserSession.tokens.idToken.toString();
        // currentUserSession.credentials.expiration =
        //   currentUserSession.credentials.expiration.toDateString();
        store.dispatch(
          setUser({
            attributes: userAttributes,
            signInUserSession: currentUserSession,
            groups: matchingGroups
          })
        );
      } else {
        signOutUser('Error fetching user details', 'please relogin again.');
        throw new Error('User (userid) not found');
      }
    } catch (error) {
      console.error(error);
    }
    store.dispatch(setUserLoading(false));
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {user?.attributes?.sub ? <Authenticated /> : <Login />}
        <Notification />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
