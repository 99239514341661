import { createSlice } from '@reduxjs/toolkit';
import { customerList } from '../../content/applications/Onespan/config';

const initialState = {
  sandbox: {
    pageLimit: 6,
    currentPage: 0,
    customerList: customerList,
    searchTerm: '',
    statusFilter: { id: 'all', name: 'All' }
  },
  production: {
    pageLimit: 6,
    currentPage: 0,
    customerList: customerList,
    searchTerm: '',
    statusFilter: { id: 'all', name: 'All' }
  },
  currentCustomer: {},
  newCustomerConfiguration: {},
  toggleCustomerConfigurationSaveButton: true,
  changedFields: []
};

export const onespanSlice = createSlice({
  name: 'onespan',
  initialState,
  reducers: {
    handlePageLimit: (state, action) => {
      state[action.payload.name].pageLimit = action.payload.value;
    },
    handlePageNumber: (state, action) => {
      state[action.payload.name].currentPage = action.payload.value;
    },
    handleStatusFilter: (state, action) => {
      state[action.payload.name].statusFilter = action.payload.value;
    },
    setCurrentCustomer: (state, action) => {
      state.currentCustomer = action.payload;
      state.newCustomerConfiguration = action.payload;
      state.toggleCustomerConfigurationSaveButton = true;
      state.changedFields = [];
    },
    setNewCustomerConfiguration: (state, action) => {
      state.newCustomerConfiguration[action.payload.key] = action.payload.value;
      if (state.changedFields.indexOf(action.payload.key) === -1) {
        state.changedFields = [...state.changedFields, action.payload.key];
      } else if (
        state.changedFields.indexOf(action.payload.key) !== -1 &&
        state.currentCustomer[action.payload.key] === action.payload.value
      ) {
        const idx = state.changedFields.indexOf(action.payload.key);
        if (idx > -1) {
          state.changedFields.splice(idx, 1);
        }
      }
    },
    setToggleCustomerConfigurationSaveButton: (state, action) => {
      state.toggleCustomerConfigurationSaveButton = action.payload;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  handlePageLimit,
  handlePageNumber,
  handleStatusFilter,
  setCurrentCustomer,
  setNewCustomerConfiguration,
  setToggleCustomerConfigurationSaveButton,
  decrement,
  incrementByAmount
} = onespanSlice.actions;

export default onespanSlice.reducer;
