import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alignment: { vertical: 'bottom', horizontal: 'right' },
  duration: 3, // in seconds
  title: '',
  type: 'success',
  description: '',
  action: {
    title: '',
    link: '',
    external: false
  },
  times: 1
};
const types = ['error', 'warning', 'info', 'success'];

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    invokeNotification: (state, action) => {
      if (action.payload.title !== undefined) {
        state.title = action.payload.title;
      }
      if (action.payload.description !== undefined) {
        state.description = action.payload.description;
      }
      if (action.payload.duration !== undefined) {
        state.duration = action.payload.duration;
      }
      if (
        action.payload.type !== undefined &&
        types.includes(action.payload.type)
      ) {
        state.type = action.payload.type;
      }
      if (
        action.payload.alignment !== undefined &&
        action.payload.alignment.vertical !== undefined &&
        action.payload.alignment.horizontal !== undefined
      ) {
        state.alignment = action.payload.alignment;
      }
      if (
        action.payload.action !== undefined &&
        action.payload.action.title !== undefined &&
        action.payload.action.link !== undefined &&
        action.payload.action.external !== undefined
      ) {
        state.action = action.payload.action;
      }
      if (action.payload.title === state.title) {
        state.times += 1;
      }
      if (action.payload.action === undefined || !action.payload.action) {
        state.action = null;
      }
      if (
        action.payload.description === undefined ||
        !action.payload.description
      ) {
        state.description = null;
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { invokeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
