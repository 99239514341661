import {
  Box,
  Typography,
  FormControl,
  Card,
  useTheme,
  Button
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';

import signOutUser from 'src/content/pages/Auth/Comman/signout';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';

import { signInWithRedirect } from 'aws-amplify/auth';

import Logo from 'src/components/LogoSign';
import { styled } from '@mui/material/styles';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Login() {
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  const signInOidc = async () => {
    try {
      await signInWithRedirect({
        customProvider: 'conga-ops-portal-oidc'
      });
    } catch (err) {
      // eslint-disable-next-line default-case
      switch (err.name) {
        case 'UserAlreadyAuthenticatedException':
          signOutUser('User signed out successfully.', err?.message);
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Ops portal</title>
      </Helmet>
      <MainContent>
        <Logo />
        <Box sx={{ mt: 6, minWidth: theme.spacing(55) }}>
          <Card sx={{ padding: 2 }}>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
              Login
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 1 }}>
              Please login via @conga.com company email ID to continue
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <LoadingButton
                loading={user.loading}
                loadingPosition="center"
                variant="contained"
                size="large"
                color="primary"
                loadingIndicator="Loading Please Wait....."
                startIcon={
                  !user.loading ? (
                    <img
                      src="/static/images/logo/microsoft.svg"
                      alt="microsoft logo"
                      width="30"
                      height="30"
                    />
                  ) : null
                }
                sx={{ mt: 2, mb: 2 }}
                onClick={() => signInOidc()}
              >
                Login Via Azure AD
              </LoadingButton>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {' '}
              <Button
                color="warning"
                size="small"
                href={`https://conga.atlassian.net/wiki/spaces/DO/pages/3046507290/OPS+PORTAL+-+HOW+TO+GET+ACCESS`}
                target="_blank"
                sx={{ mt: 1 }}
                startIcon={<MeetingRoomTwoToneIcon />}
              >
                How to get access?
              </Button>
            </Box>
          </Card>
        </Box>
      </MainContent>
    </>
  );
}

export default Login;
