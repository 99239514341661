import { signOut } from 'aws-amplify/auth';

import { store } from '../../../../store/store';

import { invokeNotification } from '../../../../store/reducers/notification';
import errorHandling from './errorHandling';

async function signOutUser(
  title = 'Signed out successfully',
  description = null
) {
  try {
    await signOut();
    console.log('User signed out successfully.');
    store.dispatch(
      invokeNotification({
        duration: 6,
        title,
        type: 'warning',
        description: description && description
      })
    );
  } catch (error) {
    console.error('Error signing out:', error);
    // Handle the error as needed.
    errorHandling(error);
  }
}

export default signOutUser;
