import { store } from '../../../../store/store';
import { invokeNotification } from '../../../../store/reducers/notification';

const errorHandling = (error) => {
  switch (error.code) {
    case 'UsernameExistsException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Email already exists.',
          type: 'error',
          description: `${error.message}`,
          action: {
            title: 'Login',
            link: '/login',
            external: false
          }
        })
      );
      console.error(
        'Username already exists. Please choose a different username.'
      );
      break;
    case 'NotSignedInException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'You are not signed in.',
          type: 'error',
          description: `${error.message}`,
          action: {
            title: 'Login',
            link: '/login',
            external: false
          }
        })
      );
      console.error(
        'Username already exists. Please choose a different username.'
      );
      break;
    case 'UserNotFoundException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Email ID not found.',
          type: 'error',
          description: `${error.message}`,
          action: {
            title: 'Signup',
            link: '/signup',
            external: false
          }
        })
      );
      console.error('Username not found. Please choose a different username.');
      break;
    case 'InvalidParameterException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Invalid parameter.',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error('Invalid parameter. Please check your input data.');
      break;
    case 'NotAuthorizedException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'You are not authorized to perform this action.',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error('You are not authorized to perform this action.');
      break;
    case 'LimitExceededException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'limit exceeded',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error('limit exceeded. Please try again later');
      break;
    case 'UserLambdaValidationException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'User Lambda validation failed',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error('User Lambda validation failed. Please contact support.');
      break;
    case 'CodeMismatchException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Verification code mismatch',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error('Verification code mismatch. Please try again.');
      break;
    case 'ExpiredCodeException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Verification code has expired',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error(
        'Verification code has expired. Please request a new code.'
      );
      break;
    case 'UserNotConfirmedException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'User is not confirmed',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error(
        'User is not confirmed. Please check your email for a confirmation link.'
      );
      break;
    case 'InvalidPasswordException':
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Invalid password',
          type: 'error',
          description: `${error.message}`
        })
      );
      console.error(
        'Invalid password. Passwords must meet the required criteria.'
      );
      break;
    default:
      store.dispatch(
        invokeNotification({
          duration: 6,
          title: 'Unknown error occured',
          type: 'error',
          description: `Please try again.  ERROR: ${error.message}`
        })
      );
      console.error('Unknown error occured:', error.message);
      break;
  }
};

export default errorHandling;
