import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  accessControl: [],
  accessFormOpen: false,
  requestedModifyAccessControl: false
};

export const userSlice = createSlice({
  name: 'userCustomData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    setAccessControl: (state, action) => {
      state.accessControl = action.payload;
    },
    setAccessFormOpen: (state, action) => {
      state.accessFormOpen = action.payload;
    },
    setRequestedModifyAccessControl: (state, action) => {
      state.requestedModifyAccessControl = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setUserData,
  setAccessControl,
  setAccessFormOpen,
  setRequestedModifyAccessControl
} = userSlice.actions;

export default userSlice.reducer;
