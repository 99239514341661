import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attributes: {},
  loading: false,
  username: null,
  groups: [],
  signInUserSession: null,
  poll: {},
  userDataKey: null,
  preferredMFA: null,
  keyPrefix: null,
  inputData: {
    username: null
  },
  users: {
    page: 0,
    limit: 10,
    list: [],
    searchTerm: '',
    loading: false,
    total: 0,
    searchActive: false,
    nextToken: null,
    prevTokens: []
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload.username;
      state.signInUserSession = { ...action.payload.signInUserSession };
      state.poll = { ...action.payload.pool };
      state.userDataKey = action.payload.userDataKey;
      state.preferredMFA = action.payload.preferredMFA;
      state.keyPrefix = action.payload.keyPrefix;
      state.attributes = action.payload.attributes;
      state.groups = action.payload.groups;
    },
    setInputdata: (state, action) => {
      state.inputData.username = action.payload.username;
    },
    deleteUser: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUsersList: (state, action) => {
      state.users.list = action.payload;
    },
    setUsersTotal: (state, action) => {
      state.users.total = action.payload;
    },
    setUsersPage: (state, action) => {
      state.users.page = action.payload;
    },
    setUsersLimit: (state, action) => {
      state.users.limit = action.payload;
    },
    setUsersSearchTerm: (state, action) => {
      state.users.searchTerm = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.users.loading = action.payload;
    },
    setSearchActive: (state, action) => {
      state.users.searchActive = action.payload;
    },
    setNextToken: (state, action) => {
      state.users.nextToken = action.payload;
    },
    setPrevTokens: (state, action) => {
      state.users.prevTokens = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  deleteUser,
  setInputdata,
  setUserLoading,
  setUsersList,
  setUsersTotal,
  setUsersPage,
  setUsersLimit,
  setUsersSearchTerm,
  setUsersLoading,
  setSearchActive,
  setNextToken,
  setPrevTokens
} = userSlice.actions;

export default userSlice.reducer;
