import React from 'react';
import {
  Box,
  Avatar,
  styled,
  Typography,
  useTheme,
  Button,
  Link as ExternalLink
} from '@mui/material';
import { Link } from 'react-router-dom';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

// const TimeLineDotWrapper = styled(TimelineDot)(
//   ({ theme, ...props }) => `
//     left: -${theme.spacing(2)};
//         margin-top: 0;
//         top: ${theme.spacing(0.5)};
//         background: ${theme.colors[props.color].lighter} !important;
//         color:${theme.colors[props.color].main};
//         `
// );

const IconContainer = styled(Avatar)(
  ({ theme, ...props }) => `
        background: ${theme.colors[props.color].lighter};
        color: ${theme.colors[props.color].main};
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
      
  `
);

const getNoticationIcon = (type) => {
  switch (type) {
    case 'success':
      return <TaskAltTwoToneIcon color={type} />;
    case 'error':
      return <ErrorTwoToneIcon color={type} />;
    case 'warning':
      return <WarningTwoToneIcon color={type} />;
    case 'info':
      return <InfoTwoToneIcon color={type} />;
    default:
      return <TaskAltTwoToneIcon color={type} />;
  }
};

const NotificationCard = ({ notification }) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <IconContainer
          variant="circular"
          sx={{ marginRight: 2 }}
          color={notification.type}
        >
          {getNoticationIcon(notification.type)}
        </IconContainer>
      </Box>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            maxWidth: theme.spacing(35),
            fontSize: theme.typography.pxToRem(13),
            marginRight:
              notification.duration > 5 ? theme.spacing(5) : theme.spacing(0)
          }}
        >
          {notification.title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: theme.spacing(35),
            fontSize: theme.typography.pxToRem(12)
          }}
        >
          {notification.description}
        </Typography>
        {notification.action !== null &&
          notification.action.title &&
          notification.action.link && (
            <>
              {notification.action.external ? (
                <ExternalLink
                  href={notification.action.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {notification.action.title}
                </ExternalLink>
              ) : (
                <Button
                  variant="contained"
                  color={notification.type}
                  size="small"
                  sx={{
                    fontSize: theme.typography.pxToRem(12),
                    marginTop: 1,
                    background: theme.colors[notification.type].lighter
                  }}
                  component={Link}
                  to={notification.action.link}
                >
                  {notification.action.title}
                </Button>
              )}
            </>
          )}
      </Box>
    </>
  );
};

export default NotificationCard;
