import React from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

const Authenticated = () => {
  const content = useRoutes(router);
  return <div>{content}</div>;
};

export default Authenticated;
