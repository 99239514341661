import LanguageIcon from '@mui/icons-material/Language';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import TodayIcon from '@mui/icons-material/Today';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const statusOptions = [
  {
    id: 'all',
    name: 'All Status',
    color: 'primary',
    productionOnly: false,
    featured: true
  },
  {
    id: 'in-progress',
    name: 'In Progress',
    color: 'primary',
    productionOnly: false,
    featured: true
  },
  {
    id: 'onboarded',
    name: 'Onboarded',
    color: 'success',
    productionOnly: false,
    featured: true
  },
  {
    id: 'account-created',
    name: 'Account created',
    color: 'primary',
    productionOnly: false,
    featured: false
  },
  {
    id: 'completed-password-reset',
    name: 'Completed password reset',
    color: 'secondary',
    productionOnly: false,
    featured: false
  },
  {
    id: 'raised-support-case',
    name: 'Raised support case',
    color: 'error',
    productionOnly: false,
    featured: false
  },
  {
    id: 'completed-account-configuration',
    name: 'Completed account configuration',
    color: 'warning',
    productionOnly: false,
    featured: false
  },
  {
    id: 'invited-admin-user',
    name: 'Invited admin user',
    color: 'info',
    productionOnly: false,
    featured: false
  },
  {
    id: 'admin-accepeted-invite',
    name: 'Admin accepted invite',
    color: 'yellow',
    productionOnly: false,
    featured: false
  },
  {
    id: 'requested-production-account',
    name: 'Requested production account',
    color: 'blue',
    productionOnly: true,
    featured: false
  }
];

export const onboardingProgressStates = [
  {
    name: 'Completed',
    color: 'success',
    icon: <TaskAltTwoToneIcon />
  },
  {
    name: 'InProgress',
    color: 'info',
    icon: <PendingTwoToneIcon />
  },
  {
    name: 'Pending',
    color: 'error',
    icon: <ErrorTwoToneIcon />
  }
];
export const onespanSandboxOnboardingPath = [
  {
    id: 1,
    title: 'Sandbox account creation',
    subTitle:
      'Creating a new sandbox account in Onespan sandbox environment hosted in US region',
    key: 'DONE_ACCOUNT_CREATION'
  },
  {
    id: 2,
    title: 'Copy account settings from base account',
    subTitle:
      'In this step we copy all required settings from base account to your newly provisioned account',
    key: 'DONE_COPY_ACCOUNT'
  },
  {
    id: 3,
    title: 'Validate Account branding',
    subTitle:
      'We validate whether the branding configured on newly provisioned account is a conga logo or not.',
    key: 'DONE_LOGO_BRANDING'
  },
  {
    id: 4,
    title: 'Validate Email templates',
    subTitle:
      'We validate whether the email templates configured on newly provisioned account is matching the email templates provided at copy account settings stage',
    key: 'DONE_EMAIL_CONFIGURATION'
  },
  {
    id: 5,
    title: 'Invite Admin User',
    subTitle:
      'Now your account is ready for first master user. So now we are inviting requested user to newly configured account',
    key: 'DONE_SEND_INVITE'
  },
  {
    id: 6,
    title: 'Waiting on admin user to accept invite',
    subTitle: 'We are waiting on admin user to accept invite',
    key: 'DONE_ACCEPT_INVITE'
  },
  {
    id: 7,
    title: 'Assigning manager role to admin user',
    subTitle:
      'In this step we provide root level permissions to the onboarded user',
    key: 'DONE_ASSIGN_MANAGER_ROLE'
  }
];

export const customerList = [
  {
    id: 'anishetty@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'account-created'
  },
  {
    id: 'anishetty1@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'admin-accepeted-invite'
  },
  {
    id: 'anishetty2@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'completed-account-configuration'
  },
  {
    id: 'anishetty3@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'invited-admin-user'
  },
  {
    onboardedDate: '2023-03-21',
    region: 'US',
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty',
    status: 'completed-password-reset',
    id: 'telespecialists-wendy-lozano-sandbox@conga-sign.com',
    adminEmailID: 'wlozano@tstelemed.com',
    adminName: 'Wendy Lozano',
    DONE_ACCEPT_INVITE: false,
    DONE_ACCOUNT_CREATION: true,
    DONE_ASSIGN_MANAGER_ROLE: false,
    DONE_COPY_ACCOUNT: false,
    DONE_EMAIL_CONFIGURATION: false,
    DONE_LOGO_BRANDING: false,
    DONE_PASSWORD_RESET: false,
    DONE_SEND_INVITE: false,
    environment: 'sandbox',
    geography: 'US',
    active: false,
    JSD: 'JSD-20608',
    onboardingStatus: 'Sent invite to customer',
    onboardingType: 'OneSpan Account Onboarding',
    onespanEmailId: 'telespecialists-wendy-lozano-sandbox@conga-sign.com',
    onespanPassword: '^V^eujek;lF%0t^ds!rU9yP23OdIV2dsDNN~$'
  },
  {
    id: 'anishetdy@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'raised-support-case'
  },
  {
    id: 'anishetsy@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'onboarded'
  },
  {
    id: 'anishetse@conga-sign.com',
    onboardedDate: '2023-03-21',
    region: 'US',
    active: true,
    companyName: 'conga',
    jiraTicketNumber: 'JSD-12345',
    onboardedBy: 'Anish shetty k',
    status: 'requested-production-account'
  }
];

export const onespanRegions = [
  {
    id: 'US',
    name: 'United States'
  },
  {
    id: 'CA',
    name: 'Canada'
  },
  {
    id: 'EU',
    name: 'Europe'
  },
  {
    id: 'AU',
    name: 'Australia'
  }
];

export const findRegion = (regionCode) => {
  let region = 'Not defined';
  region = onespanRegions.filter(({ id }) => id === regionCode)[0]?.name;
  return region;
};

export const customerMetadata = [
  {
    icon: <EmailIcon />,
    color: 'warning',
    key: 'id',
    title: 'Customer Email address',
    large: true
  },
  {
    icon: <EngineeringIcon />,
    color: 'error',
    key: 'adminDetails',
    title: 'Admin User Details',
    large: true
  },
  {
    icon: <BusinessIcon />,
    color: 'primary',
    key: 'companyName',
    title: 'Company Name',
    large: false
  },
  {
    icon: <LanguageIcon />,
    color: 'secondary',
    key: 'region',
    title: 'Onboarded Region',
    large: false
  },
  {
    icon: <TodayIcon />,
    color: 'info',
    key: 'onboardedDate',
    title: 'Onboarded',
    large: false
  },
  {
    icon: <SettingsAccessibilityIcon />,
    color: 'success',
    key: 'onboardedBy',
    title: 'Onboarded By',
    large: false
  },
  {
    icon: <FavoriteIcon />,
    color: 'primaryAlt',
    key: 'active',
    title: 'Customer Status',
    large: true
  }
];
