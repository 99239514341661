import React from 'react';
import { Snackbar, styled, Card, IconButton, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import NotificationCard from '../NotificationCard';

import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

const StyledNotificationWrapper = styled(Card)(
  ({ theme, ...props }) => `
    max-width: ${theme.spacing(150)};
    border: 1px solid ${theme.palette[props.color].main};
    padding:${theme.spacing(1)};
    display:flex;
    align-items:center;
    justify-content:space-between;
  `
);

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -27%;
  right: -8%;
`;

const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (notification.title === '') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [notification]);

  return (
    <Snackbar
      open={open}
      // eslint-disable-next-line no-unsafe-optional-chaining
      autoHideDuration={notification?.duration * 1000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: notification?.alignment.vertical,
        horizontal: notification?.alignment.horizontal
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <StyledIconButton onClick={() => handleClose()}>
          {<HighlightOffTwoToneIcon color={notification.type} />}
        </StyledIconButton>
        <StyledNotificationWrapper color={notification?.type}>
          <NotificationCard notification={notification} />
        </StyledNotificationWrapper>
      </Box>
    </Snackbar>
  );
};

export default Notification;
