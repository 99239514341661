import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { rlsEnvDetails } from 'src/data/Rls';

import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const rlsDropDownRef = useRef(null);
  const congaSignDropDownRef = useRef(null);
  const onespanDropDownRef = useRef(null);

  const [rlsDropdown, setRlsDropdown] = useState(false);
  const [congaSignDropdown, setCongaSignDropdown] = useState(false);
  const [onespanDropdown, setOnespanDropdown] = useState(false);

  const handleOpen = (dropDownType) => {
    if (dropDownType === 'onespan') {
      setOnespanDropdown(true);
    } else if (dropDownType === 'conga-sign') {
      setCongaSignDropdown(true);
    } else if (dropDownType === 'rls') {
      setRlsDropdown(true);
    }
  };

  const handleClose = (dropDownType) => {
    if (dropDownType === 'onespan') {
      setOnespanDropdown(false);
    } else if (dropDownType === 'conga-sign') {
      setCongaSignDropdown(false);
    } else if (dropDownType === 'rls') {
      setRlsDropdown(false);
    }
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={congaSignDropDownRef}
            onClick={() => handleOpen('conga-sign')}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Conga Sign
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={onespanDropDownRef}
            onClick={() => handleOpen('onespan')}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Onespan
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/turbo/general"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Turbo"
            />
          </ListItem>
        </List>
      </ListWrapper>
      <Menu
        anchorEl={rlsDropDownRef.current}
        onClose={() => handleClose('rls')}
        open={rlsDropdown}
      >
        {rlsEnvDetails.map((env) => (
          <MenuItem
            sx={{ px: 3 }}
            component={NavLink}
            to={`/rls/${env.envName.toLowerCase()}-${env.region.toLowerCase()}`}
            key={env.displayName}
            onClick={() => handleClose('rls')}
          >
            {env.envName} - {env.region}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={onespanDropDownRef.current}
        onClose={() => handleClose('onespan')}
        open={onespanDropdown}
      >
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/onespan/sandbox"
          onClick={() => handleClose('onespan')}
        >
          Sandbox
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/onespan/production"
          onClick={() => handleClose('onespan')}
        >
          Production
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={congaSignDropDownRef.current}
        onClose={() => handleClose('conga-sign')}
        open={congaSignDropdown}
      >
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/conga-sign/pentest"
          onClick={() => handleClose('conga-sign')}
        >
          Pentest
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/conga-sign/preview"
          onClick={() => handleClose('conga-sign')}
        >
          Preview
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/conga-sign/production"
          onClick={() => handleClose('conga-sign')}
        >
          Production
        </MenuItem>
      </Menu>
    </>
  );
}

export default HeaderMenu;
