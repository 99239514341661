import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const AllUsers = Loader(
  lazy(() => import('src/content/applications/Users/AllUsers'))
);
const OnespanSandbox = Loader(
  lazy(() => import('src/content/applications/Onespan/Sandbox'))
);
const CustomerProfile = Loader(
  lazy(() => import('src/content/applications/Onespan/CustomerProfile'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// RLS

const RlsCustomers = Loader(
  lazy(() => import('src/content/applications/Rls/Customers'))
);
const RlsCustomer = Loader(
  lazy(() => import('src/content/applications/Rls/Customer'))
);
const RlsMonitoring = Loader(
  lazy(() => import('src/content/applications/Rls/Monitoring'))
);
const EnvironmentDetails = Loader(
  lazy(() => import('src/content/applications/Rls/EnvironmentDetails'))
);
const OpenSearch = Loader(
  lazy(() => import('src/content/applications/Rls/OpenSearch/index.js'))
);
const OpenSearchMonitoring = Loader(
  lazy(() => import('src/content/applications/Rls/Monitoring/OpenSearch'))
);
const RedisMonitoring = Loader(
  lazy(() => import('src/content/applications/Rls/Monitoring/Redis'))
);
const PostgresMonitoring = Loader(
  lazy(() => import('src/content/applications/Rls/Monitoring/Postgres'))
);
const ReleaseJobStatus = Loader(
  lazy(() => import('src/content/applications/Rls/ReleaseJobStatus/index.js'))
);
const TidbMigrationGroup = Loader(
  lazy(() => import('src/content/applications/Rls/TidbMigration/index.js'))
);

// cloudops Home

const TriggerBuilds = Loader(
  lazy(() => import('src/content/applications/Rls/Cloudops/TriggerBuilds'))
);
const AccessRequests = Loader(
  lazy(() => import('src/content/applications/Rls/Cloudops/AccessRequests'))
);
const OpsPortalUsers = Loader(
  lazy(() => import('src/content/applications/Rls/Cloudops/Users'))
);

// status page

const InternalStatusPage = Loader(
  lazy(() =>
    import('src/content/applications/Rls/StatusPage/InternalStatusPage')
  )
);

// turbo pages

const TurboHealthChecks = Loader(
  lazy(() => import('src/content/applications/Rls/Turbo/HealthChecks'))
);

// const CloudOpsPortal = Loader(
//   lazy(() => import('src/content/applications/Rls/Cloudops'))
// );

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// AUTH
const Signup = Loader(lazy(() => import('src/content/pages/Auth/Signup')));
const Login = Loader(lazy(() => import('src/content/pages/Auth/Login')));
const ForgotPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ForgotPassword'))
);
const NewPassword = Loader(
  lazy(() => import('src/content/pages/Auth/NewPassword'))
);
const ConfirmEmail = Loader(
  lazy(() => import('src/content/pages/Auth/ConfirmEmail'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Tasks />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/signup',
        element: <Signup />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/new-password',
        element: <NewPassword />
      },
      {
        path: '/confirm-email',
        element: <ConfirmEmail />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'user-management',
    element: <SidebarLayout />,
    children: [
      {
        path: 'all-users',
        element: <AllUsers />
      }
    ]
  },
  {
    path: 'onespan',
    element: <SidebarLayout />,
    children: [
      {
        path: 'sandbox',
        element: <OnespanSandbox />
      },
      {
        path: 'production',
        element: <OnespanSandbox />
      },
      {
        path: 'sandbox/:id',
        element: <CustomerProfile />
      },
      {
        path: 'production/:id',
        element: <CustomerProfile />
      }
    ]
  },
  {
    path: 'rls',
    element: <SidebarLayout />,
    children: [
      {
        path: ':env/customers',
        element: <RlsCustomers />
      },
      {
        path: ':env/customers/:customerId',
        element: <RlsCustomer />
      },
      {
        path: ':env/environment-details',
        element: <EnvironmentDetails />
      },
      {
        path: ':env/monitoring',
        element: <RlsMonitoring />
      },
      {
        path: ':env/environment-details/open-search',
        element: <OpenSearch />
      },
      {
        path: ':env/monitoring/open-search',
        element: <OpenSearchMonitoring />
      },
      {
        path: ':env/monitoring/redis',
        element: <RedisMonitoring />
      },
      {
        path: ':env/monitoring/postgres',
        element: <PostgresMonitoring />
      },
      {
        path: ':env/releaseJobStatus',
        element: <ReleaseJobStatus />
      },
      {
        path: ':env/tidb-migration-group/:groupId',
        element: <TidbMigrationGroup />
      }
    ]
  },
  {
    path: 'cloudops-portal',
    element: <SidebarLayout />,
    children: [
      {
        path: 'trigger-builds',
        element: <TriggerBuilds />
      },
      {
        path: 'access-requests',
        element: <AccessRequests />
      },
      {
        path: 'users',
        element: <OpsPortalUsers />
      }
    ]
  },
  {
    path: 'status-page',
    element: <SidebarLayout />,
    children: [
      {
        path: 'internal-status-page',
        element: <InternalStatusPage />
      }
    ]
  },
  {
    path: 'turbo',
    element: <SidebarLayout />,
    children: [
      {
        path: 'health-checks',
        element: <TurboHealthChecks />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
