import {
  Box,
  Tooltip,
  Badge,
  tooltipClasses,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

import packageJson from '../../../package.json';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        text-decoration: none;
        margin: 0 auto;
        width:'100%';
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const theme = useTheme();

  return (
    <TooltipWrapper title="Conga Ops Portal" arrow>
      <LogoWrapper to="/overview">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Badge
            sx={{
              '.MuiBadge-badge': {
                fontSize: theme.typography.pxToRem(11),
                right: 3,
                top: 0
              }
            }}
            overlap="circular"
            color="success"
            badgeContent={packageJson.version}
          >
            <LogoSignWrapper>
              <img src="/static/images/logo/conga-logo.svg" alt="conga logo" />
            </LogoSignWrapper>
          </Badge>
          <Typography variant="h4" component="h4" mt={2} ml={2}>
            Ops Portal
          </Typography>
        </Box>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
